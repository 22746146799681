import { Box, Badge, Flex, Heading } from "@chakra-ui/react";
import { formatDateWithDay } from "../../utils/dateUtils";

const Banner = ({ pickupData }) => {
  const noOfAirportPickups = () => {
    return (
      pickupData?.students?.reduce((total, student) => {
        if (!student.isCanceled) {
          return total + student.numberOfPeopleComing;
        }
        return total;
      }, 0) || 0
    );
  };
  return (
    <Flex
      direction="column"
      align="start"
      p={2}
      bg="green"
      position="relative"
      rounded="lg"
      mb={4}
    >
      <Badge
        colorScheme="yellow"
        variant="solid"
        position="absolute"
        top={0}
        left={0}
        size={"lg"}
        transform="translate(0%, -50%)"
        zIndex="banner"
        fontSize="1em"
      >
        {noOfAirportPickups()} Airport Pickups
      </Badge>
      <Box pl={8} pt={2}>
        {" "}
        {/* Adjust padding as needed */}
        <Heading as="h2" size="xl" color="white" fontWeight="extrabold">
          {formatDateWithDay(pickupData.pickupDateTime)}
        </Heading>
      </Box>
    </Flex>
  );
};

export default Banner;
