import React from "react";
import {
  Box,
  Text,
  Link,
  Flex,
  Icon,
  Button,
  useDisclosure,
  Collapse,
} from "@chakra-ui/react";
import {
  FaMale,
  FaFlag,
  FaSuitcase,
  FaEnvelope,
  FaRing,
  FaWhatsapp,
  FaChevronDown,
  FaComment,
  FaHome,
  FaFemale,
  FaCheckCircle,
  FaTimesCircle,
  FaUndo,
  FaRegCheckCircle,
} from "react-icons/fa";

const StudentCard = ({ student, toggleCheckInStatus, toggleCancelStatus }) => {
  const { isOpen, onToggle } = useDisclosure();
  let cardBgColor = "white"; // Default background color
  if (student.isCheckedIn) {
    cardBgColor = "lightgreen"; // Background color for checked-in students
  } else if (student.isCanceled) {
    cardBgColor = "lightcoral"; // Background color for canceled students
  }
  return (
    <Box
      p={4}
      bg={cardBgColor}
      shadow="md"
      color="black"
      rounded="lg"
      borderWidth="1px"
      className={student.isCanceled ? "ribbon-red" : ""}
      data-volunteer-id={student.id}
    >
      <Flex direction="column" align="start">
        <Flex mb={2} alignItems="center">
          {student.gender === "Male" ? (
            <Icon as={FaMale} color="blue.600" mr={2} />
          ) : (
            <Icon as={FaFemale} color="pink.600" mr={2} />
          )}
          <Text fontSize="xl" fontWeight="bold" align="center">
            {student.firstName} {student.lastName} (
            {student.numberOfPeopleComing})
          </Text>
          <Icon as={FaFlag} ml={2} />
          <Text ml={1} align="center">
            {student.countryOfOrigin}
          </Text>
          <Icon as={FaSuitcase} ml={2} />
          <Text ml={1} align="center">
            {student.numberOfCheckedInLuggage}
          </Text>
        </Flex>

        <Flex align="center">
          <Icon as={FaWhatsapp} mr={2} />
          <Link href={`https://wa.me/${student.whatsappNumber}`} isExternal>
            {student.whatsappNumber}
          </Link>
          <Icon as={FaHome} mx={2} />
          <Text>{student.destinationAddress}</Text>
        </Flex>

        <Button variant="link" onClick={onToggle} mt={2}>
          <Icon as={FaChevronDown} mr={2} />
          Show More
        </Button>

        <Collapse in={isOpen}>
          <Box mt={4}>
            <Text>
              <Icon as={FaEnvelope} mr={2} />
              {student.email}
              <Icon as={FaRing} mr={2} ml={2} />
              {student.maritalStatus}
            </Text>
            {student.comments && (
              <Text>
                <Icon as={FaComment} mr={2} />
                {student.comments}
              </Text>
            )}
          </Box>
        </Collapse>
      </Flex>
      <Flex mt={4} justifyContent="space-between">
        <Button
          leftIcon={<FaWhatsapp />}
          as={Link}
          href={`https://wa.me/${student.whatsapp}`}
          isExternal
          mr={1}
          px={2}
        >
          Whatsapp
        </Button>

        <Button
          onClick={() => toggleCheckInStatus(student)}
          mr={1}
          px={2}
          leftIcon={student.isCheckedIn ? <FaUndo /> : <FaRegCheckCircle />}
        >
          {student.isCheckedIn ? "Un-Check" : "Check-In"}
        </Button>

        <Button
          px={2}
          onClick={() => toggleCancelStatus(student)}
          leftIcon={student.isCanceled ? <FaTimesCircle /> : <FaCheckCircle />}
        >
          {student.isCanceled ? "Un-Cancel" : "Cancel"}
        </Button>
      </Flex>
    </Box>
  );
};

export default StudentCard;
