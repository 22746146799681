import {
  Flex,
  Box,
  Spacer,
  Heading,
  Link as ChakraLink,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  VStack,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";

import { useContext } from "react";
import { UserContext } from "../../store/UserContext";
import { useHistory } from "react-router-dom";

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { user, setUser } = useContext(UserContext);
  const history = useHistory();

  const handleLogout = () => {
    localStorage.removeItem("user");
    setUser(null);
    history.push("/");
  };

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1rem"
      bg="blue.600"
      color="white"
    >
      <Flex align="center" mr={5}>
        <Heading as="h1" size="lg" letterSpacing={"tighter"} color="orange">
          BISM Airport Pickups
        </Heading>
      </Flex>

      <Spacer />

      <Box display={["none", "none", "flex", "flex"]} alignItems="center">
        <ChakraLink
          as={RouterLink}
          to="/"
          px={2}
          py={1}
          color="white"
          fontStyle={"bold"}
          rounded={"md"}
          _hover={{ bg: "blue.700" }}
          mr={4} // Add margin right
        >
          Home
        </ChakraLink>
        <ChakraLink
          as={RouterLink}
          to="/volunteer"
          color="white"
          fontStyle={"bold"}
          px={2}
          py={1}
          rounded={"md"}
          bg="orange.400"
          _hover={{ bg: "orange.500" }}
          mr={4} // Add margin right
        >
          Volunteer
        </ChakraLink>

        {user && user.isAdmin && (
          <ChakraLink
            as={RouterLink}
            to="/admin"
            color="white"
            fontStyle={"bold"}
            px={2}
            py={1}
            rounded={"md"}
            bg="green.400"
            _hover={{ bg: "green.500" }}
            mr={4} // Add margin right
          >
            Admin
          </ChakraLink>
        )}

        {!user && (
          <ChakraLink
            as={RouterLink}
            to="/login"
            color="white"
            fontStyle={"bold"}
            px={2}
            py={1}
            rounded={"md"}
            _hover={{ bg: "blue.700" }}
            mr={4} // Add margin right
          >
            Login
          </ChakraLink>
        )}

        {user && (
          <ChakraLink
            as={RouterLink}
            to="/"
            color="white"
            fontStyle={"bold"}
            px={2}
            py={1}
            rounded={"md"}
            _hover={{ bg: "blue.700" }}
            onClick={handleLogout}
            mr={4} // Add margin right
          >
            {" "}
            Logout{" "}
          </ChakraLink>
        )}
      </Box>

      {/* Hamburger Menu for Mobile */}
      <IconButton
        aria-label="Open Menu"
        size="lg"
        mr={2}
        icon={<HamburgerIcon />}
        display={["flex", "flex", "none", "none"]}
        onClick={onOpen}
      />

      {/* Drawer for Mobile Menu */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">Menu</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4}>
              <ChakraLink as={RouterLink} to="/" onClick={onClose}>
                Home
              </ChakraLink>
              <ChakraLink as={RouterLink} to="/volunteer" onClick={onClose}>
                Volunteer
              </ChakraLink>

              {user && user.isAdmin && (
                <ChakraLink as={RouterLink} to="/admin" onClick={onClose}>
                  Admin
                </ChakraLink>
              )}

              {!user && (
                <ChakraLink as={RouterLink} to="/login">
                  Login
                </ChakraLink>
              )}

              {user && (
                <ChakraLink as={RouterLink} to="/" onClick={handleLogout}>
                  {" "}
                  Logout{" "}
                </ChakraLink>
              )}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default Navbar;
