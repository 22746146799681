import { useState, useEffect, useMemo } from "react";
import {
  Heading,
  VStack,
  useDisclosure,

  // ... other necessary components
} from "@chakra-ui/react";

import VolunteerSlot from "../components/Volunteer/VolunteerSlot";
import AddVolunteer from "../components/Volunteer/AddVolunteer";
import EditVolunteer from "../components/Volunteer/EditVolunteer";

import useCRUD from "../hooks/useCRUD";
import { UserContext } from "../store/UserContext";
import { useContext } from "react";

const Volunteer = () => {
  const {
    errorPickupSlots,
    isPendingPickupSlots,
    data: pickupSlots,
    fetchItems: fetchPickupSlots,
  } = useCRUD("/api/pickup-groups");

  const { user } = useContext(UserContext);

  const { updateItem, isUpdatePending } = useCRUD("/api/pickup-groups");
  const { addItem: createUser } = useCRUD("/api/users");

  const {
    errorStudentData,
    isPendingStudentData,
    data: studentData,
    fetchItems: fetchStudentData,
  } = useCRUD("/api/students");

  useEffect(() => {
    fetchPickupSlots();
    fetchStudentData();
  }, [fetchPickupSlots, fetchStudentData]);

  const [volunteerRole, setVolunteerRole] = useState(""); // State to store the role
  const [selectedVolunteer, setSelectedVolunteer] = useState("");

  const [selectedPickupSlot, setSelectedPickupSlot] = useState(null); // State to store the selected pickup slot
  const [shouldLoadFormData, setShouldLoadFormData] = useState(false);

  // Modal control

  const {
    isOpen: isCreateVolunteerOpen,
    onOpen: onOpenVolunteerModal,
    onClose: onCloseVolunteerModal,
  } = useDisclosure();

  const {
    isOpen: isEditVolunteerOpen,
    onOpen: onOpenEditVolunteerModal,
    onClose: onCloseEditVolunteerModal,
  } = useDisclosure();

  const handleVolunteer = (slot, role) => {
    setSelectedPickupSlot(slot);
    setVolunteerRole(role);
    setShouldLoadFormData(true); // Signal to load form data

    onOpenVolunteerModal(); // Open the modal
  };

  const handleEditVolunteer = (slot, volunteer) => {
    setSelectedPickupSlot(slot);
    setSelectedVolunteer(volunteer);

    onOpenEditVolunteerModal(); // Open the modal
  };

  const handleCloseVolunteerModal = () => {
    setVolunteerRole(""); // Reset volunteer role
    setSelectedPickupSlot(null); // Reset selected pickup slot
    onCloseVolunteerModal(); // Close the modal using the function from useDisclosure
  };

  const handleCloseEditVolunteerModal = () => {
    setSelectedVolunteer(""); // Reset volunteer role
    setSelectedPickupSlot(null); // Reset selected pickup slot
    onCloseEditVolunteerModal(); // Close the modal using the function from useDisclosure
  };

  const getNoOfMaleStudents = (slot) => {
    // Check if studentData is available
    if (!studentData) {
      return 0;
    }

    // Find students matching the group id from slot data
    const matchingStudents = studentData.filter(
      (student) => student.groupId === slot._id && !student.isCanceled
    );

    // Count the total number of 'male' occurrences
    const totalMaleCount = matchingStudents.reduce((count, student) => {
      // Split the gender string by commas, trim, and count 'male' occurrences
      const maleCount = student.gender
        .toLowerCase()
        .split(",")
        .reduce((subCount, gender) => {
          return gender.trim() === "male" ? subCount + 1 : subCount;
        }, 0);

      return count + maleCount;
    }, 0);

    return totalMaleCount;
  };

  const getNoOfFemaleStudents = (slot) => {
    // Check if studentData is available
    if (!studentData) {
      return 0;
    }

    // Find students matching the group id from slot data
    const matchingStudents = studentData.filter(
      (student) => student.groupId === slot._id && !student.isCanceled
    );

    // Count the total number of 'female' occurrences
    const totalFemaleCount = matchingStudents.reduce((count, student) => {
      // Split the gender string by commas, trim, and count 'female' occurrences
      const femaleCount = student.gender
        .toLowerCase()
        .split(",")
        .reduce((subCount, gender) => {
          return gender.trim() === "female" ? subCount + 1 : subCount;
        }, 0);

      return count + femaleCount;
    }, 0);

    return totalFemaleCount;
  };

  // useMemo to sort the pickupSlots by pickupDateTime, putting past dates at the bottom
  const sortedPickupSlots = useMemo(() => {
    if (!pickupSlots) return [];

    return [...pickupSlots].sort((a, b) => {
      const now = new Date();
      const dateA = new Date(a.pickupDateTime);
      const dateB = new Date(b.pickupDateTime);

      // Check if either date is in the past compared to the current date
      const isPastA = dateA < now;
      const isPastB = dateB < now;

      // Both dates are either past or future: sort normally
      if (isPastA === isPastB) {
        return dateA - dateB;
      }

      // One of the dates is past and the other is future
      return isPastA ? 1 : -1;
    });
  }, [pickupSlots]);

  const createVolunteerUser = async (data) => {
    if (data) {
      try {
        await createUser(data);
      } catch (error) {
        console.error("Failed to create volunteer:", error);
      }
    }
  };

  return (
    <VStack spacing={4} p={4}>
      <Heading textAlign="center">Airport Pickup Volunteer Slots</Heading>

      {(isPendingPickupSlots || isPendingStudentData || isUpdatePending) && (
        <div>Loading...</div>
      )}
      {errorPickupSlots && <div>{errorPickupSlots}</div>}
      {errorStudentData && <div>{errorStudentData}</div>}

      {/* Add volunteer modal  */}
      <AddVolunteer
        isOpen={isCreateVolunteerOpen}
        onClose={() => {
          handleCloseVolunteerModal();
          setShouldLoadFormData(false); // Reset the flag
        }}
        type={volunteerRole} // Pass the role to the component
        isLoading={isUpdatePending}
        pickupSlot={selectedPickupSlot} // Pass the pickup slot to the component
        updateItem={updateItem}
        fetchPickupSlots={fetchPickupSlots}
        shouldLoadFormData={shouldLoadFormData}
        createVolunteerUser={createVolunteerUser}
      />

      {/* Add volunteer modal  */}

      {/* Edit volunteer modal  */}
      <EditVolunteer
        isOpen={isEditVolunteerOpen}
        onClose={() => {
          handleCloseEditVolunteerModal();
        }}
        volunteer={selectedVolunteer}
        isLoading={isUpdatePending}
        pickupSlot={selectedPickupSlot} // Pass the pickup slot to the component
        updateItem={updateItem}
        fetchPickupSlots={fetchPickupSlots}
      />

      {/* Editvolunteer modal  */}

      {sortedPickupSlots &&
        sortedPickupSlots.map((slot, index) => (
          <VolunteerSlot
            key={index}
            slot={slot}
            getNoOfMaleStudents={getNoOfMaleStudents}
            getNoOfFemaleStudents={getNoOfFemaleStudents}
            handleVolunteer={handleVolunteer}
            handleEditVolunteer={handleEditVolunteer}
            updateVolunteerSlot={updateItem}
            fetchPickupSlots={fetchPickupSlots}
            user={user}
          />
        ))}
    </VStack>
  );
};

export default Volunteer;
