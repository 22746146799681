import { useState, useCallback } from "react";

const useCRUD = (endpoint) => {
  const apiBaseUrl = process.env.REACT_APP_API_URL;

  const fullUrl = `${apiBaseUrl}${endpoint}`; // Combine base URL with endpoint

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch data (Read)
  const fetchItems = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(fullUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const fetchedData = await response.json();
      setData(fetchedData);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, [fullUrl]);

  // Add item (Create)
  const addItem = async (item) => {
    setIsLoading(true);
    try {
      const response = await fetch(fullUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(item),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      //fetchItems();
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Update item (Update)
  const updateItem = async (id, updatedItem) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${fullUrl}/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedItem),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      fetchItems();
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete item (Delete)
  const deleteItem = async (id) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${fullUrl}/${id}`, {
        method: "DELETE",
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      fetchItems();
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    error,
    isLoading,
    addItem,
    fetchItems,
    updateItem,
    deleteItem,
  };
};

export default useCRUD;
