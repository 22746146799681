import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
} from "@chakra-ui/react";

import { useState } from "react";
import { formatDate } from "../../utils/dateUtils";

const AssignGroupModal = ({
  isOpen,
  onClose,
  groups,
  selectedStudents,
  assignToGroup,
}) => {
  const [selectedGroup, setSelectedGroup] = useState("");

  const handleAssign = () => {
    assignToGroup(selectedStudents, selectedGroup);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Assigning {selectedStudents && selectedStudents.length} Students to
          Pickup Group
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select
            placeholder="Select group"
            onChange={(e) => setSelectedGroup(e.target.value)}
          >
            {groups &&
              groups.length > 0 &&
              groups.map((group) => (
                <option key={group._id} value={group._id}>
                  Group {group.groupId} - {formatDate(group.pickupDateTime)}
                </option>
              ))}
          </Select>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleAssign}>
            Assign
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AssignGroupModal;
