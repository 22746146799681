import React, { useState, useContext } from "react";
import InputMask from "react-input-mask";
import { useHistory } from "react-router-dom";
import { UserContext } from "../store/UserContext";

import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Container,
  Heading,
  Text,
} from "@chakra-ui/react";

const Login = () => {
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);

  const { setUser } = useContext(UserContext);
  const history = useHistory();

  const toast = useToast();

  const sendOtp = async () => {
    try {
      // Call your backend API to send OTP
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/login/send-otp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ mobilePhone: phone }),
        }
      );
      const responseData = await response.json(); // Parse the JSON response

      if (!responseData.success) {
        toast({
          title: "Error",
          position: "top",
          description: responseData.message,
          status: "error",
          duration: 5000,
        });
        return;
      }
      setIsOtpSent(true);
    } catch (error) {
      toast({
        title: "Error sending OTP",
        description: error.message,
        status: "error",
        duration: 5000,
      });
    }
  };

  const verifyOtp = async () => {
    try {
      // Call your backend API to verify OTP
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/login/verify-otp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ mobilePhone: phone, otp }),
        }
      );

      const responseData = await response.json(); // Parse the JSON response

      //Check if Request was successful
      if (!responseData.success) {
        toast({
          title: "Error verifying OTP",
          position: "top",
          description: responseData.message,
          status: "error",
          duration: 5000,
        });
        return;
      }

      // Login successful
      setUser(responseData.user);
      history.push("/"); // Redirect to home
    } catch (error) {
      toast({
        title: "Error verifying OTP",
        description: error.message,
        status: "error",
        duration: 5000,
      });
    }
  };

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();

    //Validate phone number
    const phoneRegex = /^\(\d{3}\)\d{3}-\d{4}$/; // Example: (123)999-9999
    if (!phone || !phoneRegex.test(phone)) {
      toast({
        title: "Please enter a  valid mobile phone number",
        status: "error",
        duration: 2000,
      });
      return;

      // Logic to send OTP
    }
    sendOtp();
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    // Logic to verify OTP

    verifyOtp();
  };

  return (
    <Container centerContent mt="10">
      <Box
        w="100%"
        maxW="400px"
        p="5"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
      >
        <Heading mb="6">Login</Heading>
        <form onSubmit={isOtpSent ? handleOtpSubmit : handlePhoneSubmit}>
          {isOtpSent && <Text>We've sent a text message to your phone.</Text>}
          <FormControl id="phone" isRequired>
            <FormLabel>Enter Mobile Phone</FormLabel>
            <InputMask
              mask="(999)999-9999"
              value={phone}
              disabled={isOtpSent}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="(123)456-7890"
            >
              {(inputProps) => <Input {...inputProps} type="tel" />}
            </InputMask>
          </FormControl>
          {isOtpSent && (
            // OTP Input Form
            <FormControl id="otp" isRequired>
              <FormLabel>Enter OTP</FormLabel>
              <Input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter OTP"
              />
            </FormControl>
          )}
          <Button colorScheme="blue" type="submit" w="100%" mt="4">
            Continue
          </Button>
          <br></br>
          {isOtpSent && (
            <div style={{ textAlign: "center" }}>
              Didn't receive an code?
              <Button variant="link" onClick={sendOtp} Resend></Button>
              <Button
                variant="link"
                onClick={() => {
                  setPhone("");
                  setOtp("");
                  setIsOtpSent(false);
                }}
              >
                Back
              </Button>
            </div>
          )}
        </form>
      </Box>
    </Container>
  );
};

export default Login;
