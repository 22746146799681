// apiService.js
const apiBaseUrl = process.env.REACT_APP_API_URL; // Ensure you have your API base URL set in your environment variables

/**
 * Deletes a volunteer from a pickup slot.
 * @param {string} slotId - The ID of the slot from which the volunteer is being removed.
 * @param {string} volId - The ID of the volunteer to remove.
 * @returns {Promise} - A promise that resolves with the response from the API.
 */
export const deleteVolunteerFromSlot = async (slotId, volId) => {
  try {
    const response = await fetch(`${apiBaseUrl}/api/users/delete-slot`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ slotId, volId }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to cancel slot.");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in deleteVolunteerFromSlot:", error.message);
    throw error;
  }
};

export const getPickupData = async (groupId) => {
  try {
    const response = await fetch(`${apiBaseUrl}/api/dashboard`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ groupId }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to retrive pickup data.");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in getPickupData:", error.message);
    throw error;
  }
};

/**
 * Fetches flight data from the API.
 * @param {string} flightNumber - The flight number to fetch data for.
 * @param {string} flightDate - The date of the flight in YYYY-MM-DD format.
 * @returns {Promise} - A promise that resolves with the flight data.
 */
export const fetchFlightData = async (flightNumber, flightDate) => {
  try {
    const response = await fetch(`${apiBaseUrl}/api/flight-status`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ flightNumber, flightDate }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch flight data.");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in fetchFlightData:", error.message);
    throw error;
  }
};
