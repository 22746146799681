import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Flex,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import CreateGroupModal from "./CreateGroupModal";
import EditGroupModal from "./EditGroupModal";
import useCRUD from "../../hooks/useCRUD";
import { formatDate } from "../../utils/dateUtils";

const PickupGroups = ({ students }) => {
  const {
    error,
    isPending,
    data: groups,
    fetchItems: fetchGroups,
  } = useCRUD("/api/pickup-groups");

  const { updateItem, isUpdatePending } = useCRUD("/api/pickup-groups");

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const {
    isOpen: isCreateModalOpen,
    onOpen: onOpenCreateModal,
    onClose: onCloseCreateModal,
  } = useDisclosure();
  const {
    isOpen: isEditModalOpen,
    onOpen: onOpenEditModal,
    onClose: onCloseEditModal,
  } = useDisclosure();

  const handleNewGroupAdded = () => fetchGroups();
  const handleEditClick = (group) => {
    setSelectedGroup(group);
    onOpenEditModal();
  };

  const toggleSortOrder = () =>
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");

  const isDatePast = (dateString) => new Date(dateString) < new Date();

  const newGroupId =
    groups && groups.length > 0
      ? Math.max(...groups.map((group) => parseInt(group.groupId, 10))) + 1
      : 1;

  const getStudentCountInGroup = (groupId) => {
    const studentsInGroup = students
      ? students.filter(
          (student) => student.groupId === groupId && !student.isCanceled
        )
      : [];
    const totalPeople = studentsInGroup.reduce(
      (acc, student) => acc + student.numberOfPeopleComing,
      0
    );
    return totalPeople;
  };

  const sortedGroups = useMemo(() => {
    if (!groups) return [];
    return [...groups].sort((a, b) =>
      sortOrder === "asc"
        ? new Date(a.pickupDateTime) - new Date(b.pickupDateTime)
        : new Date(b.pickupDateTime) - new Date(a.pickupDateTime)
    );
  }, [groups, sortOrder]);

  const getPersonInCharge = (group) => {
    if (!group) return "";

    const person = group.volunteers?.find((v) => v.personInCharge);
    return person ? person.firstName + " " + person.lastName : "";
  };

  return (
    <div className="pickup-groups-table">
      {isPending && <div>Loading...</div>}
      {error && <div>{error}</div>}
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading as="h2" size="lg">
          Pickup Groups
        </Heading>
        <Button colorScheme="blue" onClick={onOpenCreateModal}>
          Create Group
        </Button>
      </Flex>

      <CreateGroupModal
        isOpen={isCreateModalOpen}
        onClose={() => {
          onCloseCreateModal();
          handleNewGroupAdded();
        }}
        newGroupId={newGroupId}
        isLoading={isPending}
      />

      {error && <div>{error}</div>}
      {isPending && <div>Loading...</div>}
      {groups && (
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Group ID</Th>
                <Th onClick={toggleSortOrder}>
                  Pickup Date & Time {sortOrder === "asc" ? "🔼" : "🔽"}
                </Th>
                <Th>No of Students</Th>
                <Th>Coordinator</Th>
                <Th>Show Pickup Details To Student</Th>
                <Th>Dashboard Link</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortedGroups.map((data, index) => (
                <Tr
                  key={index}
                  style={
                    isDatePast(data.pickupDateTime)
                      ? { backgroundColor: "grey" }
                      : null
                  }
                >
                  <Td>{data.groupId}</Td>
                  <Td>{formatDate(data.pickupDateTime)}</Td>
                  <Td style={{ textAlign: "center" }}>
                    {getStudentCountInGroup(data._id)}
                  </Td>
                  <Td>{getPersonInCharge(data)}</Td>
                  <Td style={{ textAlign: "center" }}>
                    {data.showPickupDetails ? (
                      <CheckIcon color="green.500" />
                    ) : (
                      <CloseIcon color="red.500" />
                    )}
                  </Td>
                  <Td>
                    <Link to={`/dashboard/${data.groupId}`}>View</Link>
                  </Td>
                  <Td>
                    <Button onClick={() => handleEditClick(data)}>Edit</Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
      <EditGroupModal
        isOpen={isEditModalOpen}
        onClose={() => {
          onCloseEditModal();
          handleNewGroupAdded();
        }}
        groupData={selectedGroup}
        updateGroup={updateItem}
        isLoading={isUpdatePending}
      />
    </div>
  );
};

export default PickupGroups;
