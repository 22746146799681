import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Tooltip,
  HStack,
  Checkbox,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { formatDateTimeForInput } from "../../utils/dateUtils";

const EditGroupModal = ({
  isOpen,
  onClose,
  groupData,
  updateGroup,
  isLoading,
}) => {
  const [pickupDateTime, setPickupDateTime] = useState("");
  const [personInCharge, setPersonInCharge] = useState("");
  const [notes, setNotes] = useState("");
  const [showPickupDetails, setShowPickupDetails] = useState(false);

  useEffect(() => {
    if (groupData) {
      setPickupDateTime(formatDateTimeForInput(groupData.pickupDateTime));
      setPersonInCharge(getPersonInCharge());
      setNotes(groupData.notes);
      setShowPickupDetails(groupData.showPickupDetails || false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData]);

  const handleSubmit = async () => {
    // Find and update the person in charge
    const updatedVolunteers = groupData.volunteers.map((volunteer) => {
      if (volunteer._id === personInCharge) {
        return { ...volunteer, personInCharge: true };
      } else {
        return { ...volunteer, personInCharge: false };
      }
    });

    const updatedGroup = {
      ...groupData,
      pickupDateTime,
      notes,
      showPickupDetails,
      volunteers: updatedVolunteers,
    };

    await updateGroup(updatedGroup._id, updatedGroup);
    onClose();
  };

  const getUniqueVolunteers = () => {
    if (!groupData || !groupData.volunteers) return [];

    const uniqueVolunteers = [];
    const seenPhones = new Set();

    groupData.volunteers.forEach((volunteer) => {
      if (!seenPhones.has(volunteer.mobilePhone)) {
        seenPhones.add(volunteer.mobilePhone);
        uniqueVolunteers.push(volunteer);
      }
    });

    return uniqueVolunteers;
  };

  const getPersonInCharge = () => {
    if (!groupData) return "";

    const person = groupData.volunteers?.find((v) => v.personInCharge);
    return person ? person._id : "";
  };

  return (
    groupData && (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Group {groupData.groupId}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired mt={4}>
              <FormLabel>Pickup Date & Time</FormLabel>
              <Input
                type="datetime-local"
                value={pickupDateTime}
                onChange={(e) => setPickupDateTime(e.target.value)}
              />
            </FormControl>

            <FormControl isRequired mt={4}>
              <FormLabel>Person In-Charge</FormLabel>
              <Select
                placeholder="Select person"
                value={personInCharge}
                onChange={(e) => setPersonInCharge(e.target.value)}
              >
                {getUniqueVolunteers().map((volunteer, index) => (
                  <option key={index} value={volunteer._id}>
                    {volunteer.firstName} {volunteer.lastName}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl mt={4}>
              <Tooltip
                label="Add any additional comments here. It will be displayed on the vounteer slot"
                hasArrow
              >
                <HStack spacing={1}>
                  <InfoIcon />
                  <FormLabel>Comments</FormLabel>
                </HStack>
              </Tooltip>
              <Input
                type="text"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <Tooltip
                label="If checked, students will see pickup details on the portal"
                hasArrow
              >
                <HStack spacing={1}>
                  <InfoIcon />
                  <FormLabel>Show Pickup Details to Student</FormLabel>
                </HStack>
              </Tooltip>
              <Checkbox
                isChecked={showPickupDetails}
                onChange={(e) => setShowPickupDetails(e.target.checked)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              Update Group
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  );
};

export default EditGroupModal;
