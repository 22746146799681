import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import StudentListTable from "../components/StudentList/StudentListTable";
import PickupGroups from "../components/PickupGroups/PickupGroups";
import useCRUD from "../hooks/useCRUD";
import { useEffect } from "react";

const Admin = () => {
  const {
    data: students,
    error,
    isPending,
    fetchItems,
  } = useCRUD("/api/students");

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  return (
    <div className="admin-home">
      {error && <div>{error.message}</div>}
      {isPending && <div>Loading...</div>}
      {students && (
        <Tabs isFitted variant="enclosed">
          <TabList mb="1em">
            <Tab>List of Students</Tab>
            <Tab>List of Pickup Groups</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <StudentListTable
                students={students}
                fetchStudents={fetchItems}
              />
            </TabPanel>
            <TabPanel>
              <PickupGroups students={students} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </div>
  );
};

export default Admin;
