import { useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Button,
  useDisclosure,
  Flex,
  Heading,
  Spinner,
  Tooltip,
  ButtonGroup,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Text,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import { EditIcon, CloseIcon, SettingsIcon } from "@chakra-ui/icons";

import useCRUD from "../../hooks/useCRUD";
import AssignGroupModal from "../PickupGroups/AssignGroupModal";
import EditStudentModal from "../StudentList/EditStudentModal";
import CreateStudentModal from "../StudentList/CreateStudentModal";
import { formatTime, formatDate } from "../../utils/dateUtils";

const COLUMN_PREFERENCES_KEY = "columnPreferences";

const StudentListTable = ({ students, fetchStudents }) => {
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [sortField, setSortField] = useState("requestedPickupDateTime");
  const [sortDirection, setSortDirection] = useState("asc");
  const { addItem } = useCRUD("/api/students");
  const { updateItem } = useCRUD("/api/students");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingStudent, setEditingStudent] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalNumberOfStudents, setTotalNumberOfStudents] = useState(0);
  const [unassignedStudentsCount, setUnassignedStudentsCount] = useState(0);

  const [visibleColumns, setVisibleColumns] = useState(() => {
    const savedPreferences = localStorage.getItem(COLUMN_PREFERENCES_KEY);
    return savedPreferences
      ? JSON.parse(savedPreferences)
      : {
          groupId: true,
          pickupDateTime: true,
          numberOfPeopleComing: true,
          airlineName: true,
          flightNumber: true,
          arrivalDateTime: true,
          requestedPickupDateTime: true,
          firstName: true,
          lastName: true,
          email: true,
          mobilePhone: true,
          whatsappNumber: true,
          gender: true,
          maritalStatus: true,
          country: true,
          familyMembersComing: true,
          luggage: true,
          dropOffDestination: true,
          address: true,
          comments: true,
        };
  });

  // Load column preferences from local storage
  useEffect(() => {
    const savedPreferences = localStorage.getItem(COLUMN_PREFERENCES_KEY);
    if (savedPreferences) {
      setVisibleColumns(JSON.parse(savedPreferences));
    }
  }, []);

  // Save column preferences to local storage
  useEffect(() => {
    localStorage.setItem(
      COLUMN_PREFERENCES_KEY,
      JSON.stringify(visibleColumns)
    );
  }, [visibleColumns]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const {
    isOpen: isAssignModalOpen,
    onOpen: onAssignModalOpen,
    onClose: onAssignModalClose,
  } = useDisclosure();

  const { data: groups, fetchItems: fetchGroups } =
    useCRUD("/api/pickup-groups");

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  useEffect(() => {
    const total = students.reduce(
      (acc, student) => acc + student.numberOfPeopleComing,
      0
    );
    const unassignedCount = students.reduce((acc, student) => {
      return !student.groupId ? acc + student.numberOfPeopleComing : acc;
    }, 0);
    setTotalNumberOfStudents(total);
    setUnassignedStudentsCount(unassignedCount);
  }, [students]);

  const filteredStudents = students.filter((student) =>
    Object.values(student).some(
      (value) =>
        value !== null &&
        value !== undefined &&
        value.toString().toLowerCase().includes(searchQuery)
    )
  );

  const handleCheckboxChange = (student, isChecked) => {
    if (isChecked) {
      setSelectedStudents((prev) => [...prev, student]);
    } else {
      setSelectedStudents((prev) => prev.filter((s) => s._id !== student._id));
    }
  };

  const assignToGroup = async (selectedStudents, groupId) => {
    setIsUpdating(true);
    const updatePromises = selectedStudents.map((student) => {
      const updatedStudent = { ...student, groupId: groupId };
      return updateItem(student._id, updatedStudent);
    });
    await Promise.all(updatePromises);
    await fetchStudents();
    setSelectedStudents([]);
    setIsUpdating(false);
  };

  const getGroupPickupDateTime = (groupId) => {
    if (!groups || groups.length === 0) {
      return null;
    }
    const group = groups.find((group) => group._id == groupId);
    return group ? group.pickupDateTime : null;
  };

  const getGroupGroupId = (groupId) => {
    if (!groups || groups.length === 0) {
      return null;
    }
    const group = groups.find((group) => group._id == groupId);
    return group ? group.groupId : null;
  };

  const sortData = (data) => {
    if (!data) return [];
    return [...data].sort((a, b) => {
      let valueA = a[sortField] || "";
      let valueB = b[sortField] || "";
      if (sortDirection === "asc") {
        return valueA.toString().localeCompare(valueB.toString());
      } else {
        return valueB.toString().localeCompare(valueA.toString());
      }
    });
  };

  const handleConfirmCancel = async (selectedStudent) => {
    setIsUpdating(true);
    if (selectedStudent) {
      const cancelValue = !selectedStudent.isCanceled;
      await updateItem(selectedStudent._id, {
        ...selectedStudent,
        isCanceled: cancelValue,
      });
      await fetchStudents();
      setIsUpdating(false);
    }
  };

  const handleEdit = (student) => {
    setEditingStudent(student);
    setIsEditModalOpen(true);
  };

  const handleSave = async (updatedStudent) => {
    setIsUpdating(true);
    try {
      await updateItem(updatedStudent._id, updatedStudent);
      await fetchStudents();
    } catch (error) {
      console.error("Failed to update student:", error);
    } finally {
      setIsUpdating(false);
      setIsEditModalOpen(false);
    }
  };

  const handleCreateStudent = async (newStudentData) => {
    setIsUpdating(true);
    try {
      const studentDataWithAdminAdd = { ...newStudentData, adminAdd: true };
      await addItem(studentDataWithAdminAdd);
      await fetchStudents();
    } catch (error) {
      console.error("Failed to create student:", error);
    } finally {
      setIsUpdating(false);
      setIsCreateModalOpen(false);
    }
  };

  const handleColumnVisibilityChange = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  return (
    <div className="student-list-table">
      {isUpdating && (
        <Flex justify="center" align="center" h="100%">
          <Spinner size="xl" />
        </Flex>
      )}
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        flexDirection={{ base: "column", md: "row" }}
        gap={3}
      >
        <Heading as="h2" size={{ base: "md", md: "lg" }}>
          {totalNumberOfStudents} Students | {unassignedStudentsCount}{" "}
          Unassigned
        </Heading>
        <ButtonGroup flexDirection={{ base: "column", md: "row" }} spacing="6">
          <Button
            colorScheme="green"
            onClick={() => setIsCreateModalOpen(true)}
          >
            Add New Student
          </Button>
          <Tooltip
            label="Select at least one student to assign to a group"
            isDisabled={selectedStudents.length > 0}
          >
            <Button
              colorScheme="blue"
              onClick={onAssignModalOpen}
              isDisabled={selectedStudents.length === 0}
            >
              Assign To Pickup Group
            </Button>
          </Tooltip>
          <Menu closeOnSelect={false}>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<SettingsIcon />}
              variant="outline"
            />
            <MenuList>
              {Object.keys(visibleColumns).map((column) => (
                <MenuItem
                  key={column}
                  onClick={() => handleColumnVisibilityChange(column)}
                >
                  <Checkbox
                    isChecked={visibleColumns[column]}
                    onChange={() => handleColumnVisibilityChange(column)}
                  >
                    {column.replace(/([A-Z])/g, " $1").trim()}
                  </Checkbox>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </ButtonGroup>
      </Flex>

      <AssignGroupModal
        isOpen={isAssignModalOpen}
        onClose={onAssignModalClose}
        selectedStudents={selectedStudents}
        groups={groups}
        assignToGroup={assignToGroup}
      />

      <EditStudentModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        studentData={editingStudent}
        onSave={handleSave}
      />

      <CreateStudentModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onSave={handleCreateStudent}
      />

      <Input
        placeholder="Search students..."
        value={searchQuery}
        onChange={handleSearchChange}
        mb={4}
        size="md"
      />

      <Box>
        <Text color="red.500">
          {Object.keys(visibleColumns).some((key) => !visibleColumns[key]) &&
            "Some columns are hidden"}
        </Text>
      </Box>

      {students && (
        <Box
          className="custom-scrollbar"
          maxW="100%"
          overflowX="auto"
          maxH="500px"
        >
          <Table variant="simple" size="sm" minWidth="1500px" borderWidth="1px">
            <Thead>
              <Tr>
                <Th></Th>
                {visibleColumns.groupId && (
                  <Th
                    onClick={() => {
                      setSortField("groupId");
                      setSortDirection(
                        sortDirection === "asc" ? "desc" : "asc"
                      );
                    }}
                  >
                    Grp
                    {sortField === "groupId" &&
                      (sortDirection === "asc" ? "🔼" : "🔽")}
                  </Th>
                )}
                {visibleColumns.pickupDateTime && <Th>Pickup Date/Time</Th>}
                {visibleColumns.numberOfPeopleComing && <Th>No. of Ppl</Th>}
                {visibleColumns.airlineName && <Th>Airline Name</Th>}
                {visibleColumns.flightNumber && <Th>Flight Number</Th>}
                {visibleColumns.arrivalDateTime && <Th>Arrival Date/Time</Th>}
                {visibleColumns.requestedPickupDateTime && (
                  <Th
                    onClick={() => {
                      setSortField("requestedPickupDateTime");
                      setSortDirection(
                        sortDirection === "asc" ? "desc" : "asc"
                      );
                    }}
                  >
                    Requested Pickup Date/Time
                    {sortField === "requestedPickupDateTime" &&
                      (sortDirection === "asc" ? "🔼" : "🔽")}
                  </Th>
                )}
                {visibleColumns.firstName && <Th>First Name</Th>}
                {visibleColumns.lastName && <Th>Last Name</Th>}
                {visibleColumns.email && <Th>Email</Th>}
                {visibleColumns.mobilePhone && <Th>Mobile Phone</Th>}
                {visibleColumns.whatsappNumber && <Th>WhatsApp #</Th>}
                {visibleColumns.gender && <Th>Gender</Th>}
                {visibleColumns.maritalStatus && <Th>Marital Status</Th>}
                {visibleColumns.country && <Th>Country</Th>}
                {visibleColumns.familyMembersComing && (
                  <Th>Family Members Coming</Th>
                )}
                {visibleColumns.luggage && <Th>Luggage</Th>}
                {visibleColumns.dropOffDestination && (
                  <Th>Drop-off Destination</Th>
                )}
                {visibleColumns.address && <Th>Address</Th>}
                {visibleColumns.comments && <Th>Comments</Th>}
                <Th></Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortData(filteredStudents).map((data, index) => (
                <Tr key={index} bg={data.isCanceled ? "lightcoral" : "none"}>
                  <Td>
                    <Checkbox
                      isChecked={selectedStudents.some(
                        (s) => s._id === data._id
                      )}
                      onChange={(e) =>
                        handleCheckboxChange(data, e.target.checked)
                      }
                    />
                  </Td>
                  {visibleColumns.groupId && (
                    <Td>
                      <Link
                        style={{
                          color: "blue",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                        to={`/dashboard/${getGroupGroupId(data.groupId)}`}
                      >
                        {getGroupGroupId(data.groupId)}
                      </Link>
                    </Td>
                  )}
                  {visibleColumns.pickupDateTime && (
                    <Td>
                      {getGroupPickupDateTime(data.groupId) &&
                        formatDate(getGroupPickupDateTime(data.groupId))}
                    </Td>
                  )}
                  {visibleColumns.numberOfPeopleComing && (
                    <Td>{data.numberOfPeopleComing}</Td>
                  )}
                  {visibleColumns.airlineName && <Td>{data.airlineName}</Td>}
                  {visibleColumns.flightNumber && <Td>{data.flightNumber}</Td>}
                  {visibleColumns.arrivalDateTime && (
                    <Td>{data.arrivalDateTime}</Td>
                  )}
                  {visibleColumns.requestedPickupDateTime && (
                    <Td>{data.requestedPickupDateTime}</Td>
                  )}
                  {visibleColumns.firstName && <Td>{data.firstName}</Td>}
                  {visibleColumns.lastName && <Td>{data.lastName}</Td>}
                  {visibleColumns.email && <Td>{data.email}</Td>}
                  {visibleColumns.mobilePhone && <Td>{data.mobilePhone}</Td>}
                  {visibleColumns.whatsappNumber && (
                    <Td>{data.whatsappNumber}</Td>
                  )}
                  {visibleColumns.gender && <Td>{data.gender}</Td>}
                  {visibleColumns.maritalStatus && (
                    <Td>{data.maritalStatus}</Td>
                  )}
                  {visibleColumns.country && <Td>{data.countryOfOrigin}</Td>}
                  {visibleColumns.familyMembersComing && (
                    <Td>{data.familyMembersArrivingWithYou}</Td>
                  )}
                  {visibleColumns.luggage && (
                    <Td>{data.numberOfCheckedInLuggage}</Td>
                  )}
                  {visibleColumns.dropOffDestination && (
                    <Td>{data.dropOffDestination}</Td>
                  )}
                  {visibleColumns.address && <Td>{data.destinationAddress}</Td>}
                  {visibleColumns.comments && <Td>{data.comments}</Td>}
                  <Td
                    sx={{ width: "10px", minWidth: "10px", maxWidth: "10px" }}
                  >
                    <Tooltip label="Edit Details about a student.">
                      <Button
                        variant="ghost"
                        colorScheme="blue"
                        ml={2}
                        onClick={() => handleEdit(data)}
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                  </Td>
                  <Td
                    sx={{ width: "10px", minWidth: "10px", maxWidth: "10px" }}
                  >
                    {!data.isCanceled && (
                      <Tooltip label="Cancel Student. Will color the row red.">
                        <Button
                          variant="ghost"
                          colorScheme="red"
                          ml={2}
                          onClick={() => handleConfirmCancel(data)}
                        >
                          <CloseIcon />
                        </Button>
                      </Tooltip>
                    )}
                    {data.isCanceled && (
                      <Tooltip label="Un-Cancel Student.">
                        <Button
                          variant="ghost"
                          colorScheme="green"
                          ml={2}
                          onClick={() => handleConfirmCancel(data)}
                        >
                          <CloseIcon />
                        </Button>
                      </Tooltip>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </div>
  );
};

export default StudentListTable;
