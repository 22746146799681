import { Text, Flex, Badge } from "@chakra-ui/react";
const FlightStatusDisplay = ({ flightStatus }) => {
  if (!flightStatus) {
    return <Text>Loading flight status...</Text>;
  }

  const formatTime = (dateString) => {
    return new Date(dateString).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const status = flightStatus.status;
  const isDelayed = flightStatus.isDelayed;
  const isEarly = flightStatus.isEarly;

  const scheduledArrival = formatTime(flightStatus.scheduledArrival);
  const estimatedArrival = flightStatus.estimatedArrival
    ? formatTime(flightStatus.estimatedArrival)
    : null;
  const actualArrival = flightStatus.actualArrival
    ? formatTime(flightStatus.actualArrival)
    : null;

  return (
    <Flex direction="column" align="flex-start">
      {status === "C" && (
        <Badge colorScheme="red" variant="solid" fontSize="lg" px={3}>
          Canceled
        </Badge>
      )}
      {status === "A" && (
        <>
          {isDelayed && (
            <Badge colorScheme="orange" variant="solid" fontSize="lg" px={3}>
              Arriving Late - {estimatedArrival}
            </Badge>
          )}
          {isEarly && (
            <Badge colorScheme="green" variant="solid" fontSize="lg" px={3}>
              Arriving Early - {estimatedArrival}
            </Badge>
          )}
          {!isDelayed && !isEarly && (
            <Badge colorScheme="blue" variant="solid" fontSize="lg" px={3}>
              On-Time - {scheduledArrival}
            </Badge>
          )}
        </>
      )}
      {status === "S" && (
        <Badge colorScheme="yellow" variant="solid" fontSize="lg" px={3}>
          Scheduled - {scheduledArrival}
        </Badge>
      )}
      {status === "L" && (
        <Badge colorScheme="green" variant="solid" fontSize="lg" px={3}>
          Arrived - {actualArrival}
        </Badge>
      )}
    </Flex>
  );
};

export default FlightStatusDisplay;
