import {
  Box,
  Flex,
  HStack,
  Text,
  Button,
  Badge,
  Icon,
  VStack,
  Heading,
  Grid,
  useBreakpointValue,
  Tooltip,
} from "@chakra-ui/react";
import {
  FaChurch,
  FaMale,
  FaFemale,
  FaMobileAlt,
  FaStar,
} from "react-icons/fa";
import { CloseIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

import { formatDateWithDayShort } from "../../utils/dateUtils";
import { formatPhoneNumber } from "../../utils/phoneNumberUtils";

const VolunteerSlot = ({
  slot = {},
  handleVolunteer,
  handleEditVolunteer,
  getNoOfFemaleStudents,
  getNoOfMaleStudents,
  updateVolunteerSlot,
  fetchPickupSlots,
  user,
}) => {
  const maleStudents = getNoOfMaleStudents(slot);
  const femaleStudents = getNoOfFemaleStudents(slot);
  const totalStudents = maleStudents + femaleStudents;

  // Filter the volunteers based on their roles
  const drivers = slot.volunteers?.filter((v) => v.isDriver) || [];
  const greeters = slot.volunteers?.filter((v) => !v.isDriver) || [];

  // Calculate total accommodation capacity of all drivers
  const totalAccommodation = drivers.reduce(
    (acc, driver) => acc + driver.canAccomodate,
    0
  );

  const slotsRemaining = totalStudents - totalAccommodation;

  const isDatePast = new Date(slot.pickupDateTime) < new Date();

  const isMobile = useBreakpointValue({ base: true, md: false });
  const badgeHeight = "24px"; // Adjust this value as needed

  const handleConfirmCancel = async (volunteerId) => {
    const confirmCancel = window.confirm(
      "Are you sure you want to cancel this volunteer?"
    );
    if (confirmCancel) {
      // Logic to remove the volunteer from the slot
      const updatedVolunteers = slot.volunteers.filter(
        (volunteer) => volunteer._id !== volunteerId
      );
      const updatedSlot = { ...slot, volunteers: updatedVolunteers };

      await updateVolunteerSlot(slot._id, updatedSlot); // This function should handle updating the state or making an API call

      fetchPickupSlots();
    }
  };

  return (
    <Box
      position="relative"
      p={4}
      borderWidth="1px"
      shadow="md"
      rounded="lg"
      w="100%"
      style={isDatePast ? { backgroundColor: "#D3D3D3" } : null}
    >
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        {/* Students Badge */}
        <Badge
          position="absolute"
          top="-1"
          left="-1"
          px={2}
          py={1}
          height={badgeHeight}
          display="flex"
          alignItems="center"
          variant="solid"
          colorScheme="green"
          shadow="md"
          zIndex={1}
        >
          Students: {totalStudents}
          <Badge colorScheme="blue" px={2} py={0} ml={2}>
            <HStack spacing={2}>
              <Icon as={FaMale} />
              <Text>{maleStudents}</Text>
            </HStack>
          </Badge>
          <Badge colorScheme="pink" px={2} py={0}>
            <HStack spacing={2} justifyContent="center">
              <Icon as={FaFemale} />
              <Text>{femaleStudents}</Text>
            </HStack>
          </Badge>
        </Badge>

        {/* Coordinator Badge / Dashboard Link */}

        <Link to={`/dashboard/${slot.groupId}`}>
          <Badge
            colorScheme="blue"
            position="absolute"
            variant="solid"
            fontSize="sm"
            ml="2"
            top="-1"
            right="0"
            px={1}
            py={1}
            mb={2}
            height={badgeHeight}
            alignItems="center"
          >
            Dashboard <ExternalLinkIcon />
          </Badge>
        </Link>

        {/* Group ID Badge - Hidden on mobile */}
        {user && user.isAdmin && !isMobile && (
          <Badge
            colorScheme="yellow"
            variant="solid"
            fontSize="sm"
            position="absolute"
            top="20"
            left="0"
          >
            Group: {slot.groupId}
          </Badge>
        )}
      </Flex>

      <Flex justifyContent="space-between" alignItems="center">
        <Box w="full">
          <Heading size="md" textAlign="center" mt={2}>
            {formatDateWithDayShort(slot.pickupDateTime)}
          </Heading>
          {/* {personInCharge && (
            <Tooltip label="Point person for communicating with students and volunteers">
              <Text textAlign="center" mt={1} fontSize="sm">
                Coordinator: {personInCharge.firstName}{" "}
                {personInCharge.lastName}
              </Text>
            </Tooltip>
          )} */}
        </Box>
      </Flex>

      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(3, minmax(0, 1fr))",
        }}
        gap={4}
      >
        {
          <VStack spacing={2} w="full">
            {" "}
            {/* Set width to full */}
            <Box
              borderRadius="lg"
              p={2}
              w="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Text color={"red.500"} fontWeight={"bold"} textAlign="center">
                {slotsRemaining > 0 && `${slotsRemaining} Slots Remaining`}
              </Text>
              {slot.notes && (
                <Text fontWeight={"italic"} textAlign="center">
                  {slot.notes}
                </Text>
              )}
            </Box>
          </VStack>
        }
        {/* Volunteer as Driver Box */}
        <VStack spacing={2} w="full" align="stretch">
          <Box borderWidth="1px" borderRadius="lg" p={2}>
            <Button
              width="100%"
              size="sm"
              onClick={() => handleVolunteer(slot, "Driver")}
              colorScheme="yellow"
              isDisabled={isDatePast}
            >
              {isDatePast ? "Drivers" : "Volunteer as Driver"}
            </Button>
            <VStack spacing={2} mt={2}>
              {drivers.map((volunteer, index) => (
                <Flex
                  key={index}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {volunteer.personInCharge && (
                    <Tooltip label="Point person for communicating with students and volunteers">
                      <span>
                        <Icon as={FaStar} color="green" />
                      </span>
                    </Tooltip>
                  )}
                  {volunteer.canDriveChurchVan && <Icon as={FaChurch} />}
                  <Flex
                    wrap={{ base: "wrap", md: "nowrap" }}
                    alignItems="center"
                    ml={2}
                  >
                    <Text
                      style={{ fontSize: "smaller", whiteSpace: "nowrap" }}
                      onClick={() => handleEditVolunteer(slot, volunteer)}
                    >
                      {volunteer.firstName} {volunteer.lastName} (
                      {volunteer.canAccomodate})
                    </Text>
                    <a
                      href={`sms:${volunteer.mobilePhone}`}
                      style={{ fontSize: "smaller", whiteSpace: "nowrap" }}
                    >
                      <Icon as={FaMobileAlt} pt={1} />
                      {formatPhoneNumber(volunteer.mobilePhone)}
                    </a>

                    {user && user.isAdmin && (
                      <Tooltip label="Cancel Volunteer">
                        <Button
                          variant="ghost"
                          colorScheme="red"
                          size="xs"
                          ml={2}
                          onClick={() => handleConfirmCancel(volunteer._id)}
                          alignItems="center"
                        >
                          <CloseIcon />
                        </Button>
                      </Tooltip>
                    )}
                  </Flex>{" "}
                </Flex>
              ))}
            </VStack>
          </Box>
        </VStack>

        {/* Volunteer as Greeter Box */}
        <VStack spacing={2} w="full" align="stretch">
          <Box borderWidth="1px" borderRadius="lg" p={2}>
            <Button
              width="100%"
              size="sm"
              onClick={() => handleVolunteer(slot, "Greeter")}
              colorScheme="yellow"
              isDisabled={isDatePast}
            >
              {isDatePast ? "Greeters" : "Volunteer as Greeter"}
            </Button>
            <VStack spacing={2} mt={2}>
              {greeters.map((volunteer, index) => (
                <Flex
                  key={index}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {" "}
                  {volunteer.personInCharge && (
                    <Tooltip label="Point person for communicating with students and volunteers">
                      <span>
                        <Icon as={FaStar} color="green" />
                      </span>
                    </Tooltip>
                  )}
                  <Text
                    style={{ fontSize: "smaller", whiteSpace: "nowrap" }}
                    onClick={() => handleEditVolunteer(slot, volunteer)}
                  >
                    {volunteer.firstName} {volunteer.lastName}
                  </Text>
                  <a
                    href={`sms:${volunteer.mobilePhone}`}
                    style={{ fontSize: "smaller", whiteSpace: "nowrap" }}
                  >
                    <Icon as={FaMobileAlt} pt={1} />
                    {formatPhoneNumber(volunteer.mobilePhone)}
                  </a>
                  {user && user.isAdmin && (
                    <Tooltip label="Cancel Volunteer">
                      <Button
                        variant="ghost"
                        colorScheme="red"
                        size="xs"
                        ml={2}
                        onClick={() => handleConfirmCancel(volunteer._id)}
                        alignItems="center"
                      >
                        <CloseIcon />
                      </Button>
                    </Tooltip>
                  )}{" "}
                </Flex>
              ))}
            </VStack>
          </Box>
        </VStack>
      </Grid>
    </Box>
  );
};

export default VolunteerSlot;
