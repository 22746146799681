/* eslint-disable eqeqeq */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Heading,
  Box,
  Grid,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Checkbox,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import InputMask from "react-input-mask";

import { formatDateWithDay } from "../../utils/dateUtils";

const EditVolunteer = ({
  isOpen,
  onClose,
  volunteer,
  isLoading,
  pickupSlot,
  updateItem,
  fetchPickupSlots,
}) => {
  const [validationMessage, setValidationMessage] = useState("");

  const [formData, setFormData] = useState(volunteer || {});
  const [type, setType] = useState("");
  const [noOfCanAccomodate, setNoOfCanAccomodate] = useState(0);

  useEffect(() => {
    if (volunteer) {
      setFormData({
        ...volunteer,
        canAccomodate: volunteer.canAccomodate
          ? parseInt(volunteer.canAccomodate, 10)
          : 0,
        canDriveChurchVan: volunteer.canDriveChurchVan == true, // Convert to boolean if necessary
      });
      setNoOfCanAccomodate(
        volunteer.canAccomodate ? volunteer.canAccomodate : 0
      );
      setType(volunteer.isDriver ? "Driver" : "Greeter");
    } else {
      setFormData({}); // Reset or set to default values
    }
  }, [volunteer]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prev) => ({ ...prev, [name]: checked }));
    } else if (name === "canAccomodate") {
      setFormData((prev) => ({ ...prev, [name]: parseInt(value, 10) || 0 }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async () => {
    if (!formData.firstName || !formData.lastName || !formData.mobilePhone) {
      setValidationMessage("Please fill in name and phone number");
      return;
    }

    if (volunteer.isDriver && !formData.canAccomodate) {
      setValidationMessage(
        "Please fill in How Many People You Can Accommodate"
      );
      return;
    }

    // Phone number format validation
    const phoneRegex = /^\(\d{3}\)\d{3}-\d{4}$/;
    if (!phoneRegex.test(formData.mobilePhone)) {
      setValidationMessage(
        "Invalid phone number format. Expected: (000)000-0000"
      );
      return;
    }

    // Find the index of the volunteer in the existing array
    const volunteerIndex = pickupSlot.volunteers.findIndex(
      (v) => v._id === volunteer._id
    );

    // Update the volunteer data at the found index
    const updatedVolunteers = [...pickupSlot.volunteers];
    updatedVolunteers[volunteerIndex] = { ...formData };

    // Update the pickup slot with the modified volunteers array
    const updatedSlot = {
      ...pickupSlot,
      volunteers: updatedVolunteers,
    };

    await updateItem(updatedSlot._id, updatedSlot);

    fetchPickupSlots();
    onClose();
  };
  return (
    pickupSlot &&
    volunteer && (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Edit Volunteer Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form id="createStudentForm" onSubmit={handleSubmit}>
              {/* Student Details */}
              <Box mb={6}>
                <Heading size="md" mb={4}>
                  {formatDateWithDay(pickupSlot.pickupDateTime)}{" "}
                </Heading>
                {validationMessage && (
                  <Box color="red.500" mb={4}>
                    {validationMessage}
                  </Box>
                )}
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  {/* First Name */}
                  <FormControl isRequired mb={4}>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      name="firstName"
                      onChange={handleChange}
                      value={formData?.firstName || ""}
                    />
                  </FormControl>

                  {/* Last Name */}
                  <FormControl isRequired mb={4}>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      name="lastName"
                      onChange={handleChange}
                      value={formData?.lastName || ""}
                    />
                  </FormControl>

                  {/* Mobile Phone */}
                  <FormControl isRequired mb={4}>
                    <FormLabel>Mobile Phone</FormLabel>
                    <InputMask
                      mask="(999)999-9999"
                      value={formData.mobilePhone}
                      onChange={handleChange}
                      disabled={isLoading}
                    >
                      {(inputProps) => (
                        <Input {...inputProps} name="mobilePhone" />
                      )}
                    </InputMask>
                  </FormControl>

                  {type == "Driver" && (
                    /* Capacity */
                    <FormControl isRequired mb={4}>
                      <FormLabel>Capacity</FormLabel>
                      <NumberInput defaultValue={noOfCanAccomodate} min={0}>
                        <NumberInputField
                          name="canAccomodate"
                          onChange={handleChange}
                          value={formData.canAccomodate}
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                  )}
                  {type == "Driver" && (
                    <FormControl mb={4}>
                      <Checkbox
                        name="canDriveChurchVan"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            canDriveChurchVan: e.target.checked,
                          })
                        }
                        isChecked={formData.canDriveChurchVan || false}
                      >
                        I will drive the church's van
                      </Checkbox>
                    </FormControl>
                  )}
                </Grid>
              </Box>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSubmit}>
              {isLoading ? <Spinner size="sm" /> : "Volunteer"}
            </Button>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  );
};

export default EditVolunteer;
