import Navbar from "./components/Layout/Navbar";
import Home from "./views/Home";
import Admin from "./views/Admin";
import Volunteer from "./views/Volunteer";
import Login from "./views/Login";
import { UserProvider, UserContext } from "./store/UserContext";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import React, { useContext } from "react";
import PickupDashboard from "./views/PickupDashboard";

function App() {
  return (
    <UserProvider>
      <Router>
        <div className="App">
          <Navbar />
          <div className="content">
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/volunteer">
                <Volunteer />
              </Route>
              <Route path="/dashboard/:id">
                <PickupDashboard />
              </Route>
              <Route path="/admin">
                <ProtectedAdminRoute />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              {/* Add more routes as needed */}
            </Switch>
          </div>
        </div>
      </Router>
    </UserProvider>
  );
}

// Protected Admin Route component
function ProtectedAdminRoute() {
  const { user } = useContext(UserContext);

  if (user && user.isAdmin) {
    return <Admin />;
  } else {
    return <Redirect to="/" />;
  }
}

export default App;
