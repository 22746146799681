import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Tooltip,
  HStack,
  Checkbox,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useState } from "react";

import useCRUD from "../../hooks/useCRUD";

const CreateGroupModal = ({ isOpen, onClose, newGroupId, isLoading }) => {
  const { addItem } = useCRUD("/api/pickup-groups");

  const [pickupDateTime, setPickupDateTime] = useState("");
  const [notes, setNotes] = useState("");
  const [showPickupDetails, setShowPickupDetails] = useState(false);

  const handleSubmit = async () => {
    const utcDateTime = new Date(pickupDateTime).toISOString();

    const newGroup = {
      groupId: newGroupId,
      pickupDateTime: utcDateTime, // Use UTC date time here
      notes,
      showPickupDetails,
      // other fields as necessary
    };
    await addItem(newGroup);
    onClose(); // Close modal after submission
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Group</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isRequired>
            <FormLabel>Group ID</FormLabel>
            <Input value={newGroupId} readOnly />
          </FormControl>

          <FormControl isRequired mt={4}>
            <FormLabel>Pickup Date & Time</FormLabel>
            <Input
              type="datetime-local"
              value={pickupDateTime}
              onChange={(e) => setPickupDateTime(e.target.value)}
            />
          </FormControl>

          <FormControl mt={4}>
            <Tooltip
              label="Add any additional comments here. It will be displayed on the vounteer slot"
              hasArrow
            >
              <HStack spacing={1}>
                <InfoIcon />
                <FormLabel>Comments</FormLabel>
              </HStack>
            </Tooltip>
            <Input
              type="text"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </FormControl>

          <FormControl mt={4}>
            <Tooltip
              label="If checked, students will see pickup details on the portal"
              hasArrow
            >
              <HStack spacing={1}>
                <InfoIcon />
                <FormLabel>Show Pickup Details to Student</FormLabel>
              </HStack>
            </Tooltip>
            <Checkbox
              isChecked={showPickupDetails}
              onChange={(e) => setShowPickupDetails(e.target.checked)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSubmit}>
            {isLoading ? <Spinner size="sm" /> : "Create Group"}
          </Button>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateGroupModal;
