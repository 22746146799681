import { useContext } from "react";
import { UserContext } from "../store/UserContext";
import { Box } from "@chakra-ui/react";
import GuestContent from "../components/HomePage/GuestContent.";
import UserContent from "../components/HomePage/UserContent";

const Home = () => {
  const { user } = useContext(UserContext);

  return (
    <Box p={6}>{user ? <UserContent user={user} /> : <GuestContent />}</Box>
  );
};

export default Home;
