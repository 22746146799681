import { Link } from "react-router-dom";
import welcomeImage from "../../assets/images/welcome-airport.jpeg";
import { Button, Box, Heading, Text, Image, Center } from "@chakra-ui/react";

const GuestContent = () => {
  return (
    <Center flexDirection="column" textAlign="center">
      <Box>
        <Heading as="h1" size="2xl" mb={4}>
          Welcome to BISM Airport Pickups
        </Heading>
        <Text fontSize="xl" color="gray.600" mb={4}>
          July 29 - August 10, 2024. Pickup students from Indianapolis Airport
          using your own vehicle or your church van. Most pickups are at 3 or
          11pm. There are some mid-morning and early evening pickups as well.
        </Text>
        <Button
          as={Link}
          to="/volunteer"
          colorScheme="blue"
          size="lg"
          shadow="md"
          mb={4}
        >
          Volunteer Now
        </Button>
      </Box>
      <Image
        src={welcomeImage}
        alt="Welcome to BISM Airport Pickups"
        boxSize="50%"
        objectFit="cover"
        borderRadius="lg"
        shadow="lg"
      />
    </Center>
  );
};

export default GuestContent;
