import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";

const UploadProfilePicModal = ({ isOpen, onClose, userId, onUpload }) => {
  const [file, setFile] = useState(null);
  const toast = useToast();

  const handleFileChange = (e) => setFile(e.target.files[0]);

  const handleUpload = async () => {
    if (!file) {
      toast({
        title: "Error",
        description: "Please select a file to upload.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("profile_picture", file);
    formData.append("user_id", userId);

    try {
      // Step 1: Upload the profile picture to the Laravel server
      const laravelResponse = await fetch(
        `${process.env.REACT_APP_LARAVEL_API_URL}/airport/upload-profile-picture`,
        {
          method: "POST",
          body: formData,
        }
      );

      const laravelData = await laravelResponse.json();

      if (!laravelResponse.ok) {
        throw new Error(
          laravelData.message || "Failed to upload profile picture."
        );
      }

      const profilePicUrl = laravelData.profile_pic_url;
      console.log(profilePicUrl); // Console log the URL

      const profileUrl = process.env.REACT_APP_LARAVEL_API_URL + profilePicUrl;

      // Step 2: Update the user's profile picture URL in the Node.js backend
      const nodeResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/update-profile-pic-url`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId, profileUrl }),
        }
      );

      const nodeData = await nodeResponse.json();

      if (!nodeResponse.ok) {
        throw new Error(
          nodeData.message || "Failed to update profile picture URL."
        );
      }

      toast({
        title: "Success",
        description: "Profile picture uploaded and updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onUpload();
      onClose();
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to upload profile picture.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Profile Picture</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Profile Picture</FormLabel>
            <Input type="file" onChange={handleFileChange} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleUpload}>
            Upload
          </Button>
          <Button variant="ghost" onClick={onClose} ml={3}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UploadProfilePicModal;
