import React, { useState, useEffect } from "react";
import { Box, Text, Link, Icon, Flex, SimpleGrid } from "@chakra-ui/react";
import { FaPlaneDeparture, FaUsers } from "react-icons/fa";
import StudentCard from "./StudentCard";
import FlightStatusDisplay from "./FlightStatusDisplay";

import { fetchFlightData } from "../../services/ApiService";

const FlightCard = ({
  flightNumber,
  students,
  toggleCheckInStatus,
  toggleCancelStatus,
  pickupData,
}) => {
  const [flightStatus, setFlightStatus] = useState(null);

  useEffect(() => {
    const fetchFlightStatus = async () => {
      // Check if the pickup date is today
      const pickupDate = new Date(pickupData.pickupDateTime);
      const today = new Date();
      if (pickupDate.toDateString() === today.toDateString()) {
        try {
          const formattedDate = `${pickupDate.getFullYear()}-${String(
            pickupDate.getMonth() + 1
          ).padStart(2, "0")}-${String(pickupDate.getDate()).padStart(2, "0")}`;
          const flightStatusData = await fetchFlightData(
            flightNumber,
            formattedDate
          );
          setFlightStatus(flightStatusData);
        } catch (error) {
          console.error("Error fetching flight status:", error);
        }
      } else {
        console.log("Pickup date is not today");
      }
    };

    fetchFlightStatus();
  }, [flightNumber, pickupData]);

  const totalPeople = students.reduce(
    (acc, student) => acc + student.numberOfPeopleComing,
    0
  );

  // Function to convert UTC time to local time

  return (
    <Box
      bg="#004a7e"
      color="white"
      p={4}
      rounded="lg"
      shadow="md"
      mb={4}
      position="relative"
      zIndex={2}
    >
      {flightStatus && (
        <Box position="absolute" top={-2} left={-2} zIndex={1}>
          <FlightStatusDisplay flightStatus={flightStatus} />
        </Box>
      )}
      <Link
        href={`https://www.google.com/search?q=${flightNumber}`}
        isExternal
        style={{ color: "white" }}
      >
        <Flex align="center" justify="center">
          <Icon as={FaPlaneDeparture} color="white" mr={2} w={6} h={6} />
          <Text as="span" fontSize="xl" ml={1}>
            {flightNumber} (
            <Icon as={FaUsers} color="white" ml={1} w={6} h={6} /> {totalPeople}
            )
          </Text>
        </Flex>
      </Link>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4} mt={4}>
        {students.map((student) => (
          <StudentCard
            key={student.id}
            student={student}
            toggleCheckInStatus={toggleCheckInStatus}
            toggleCancelStatus={toggleCancelStatus}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default FlightCard;
