import React, { useState, useEffect } from "react";
import FlightCard from "./FlightCard";
import { getPickupData } from "../../services/ApiService";
import Banner from "./Banner";
import useCRUD from "../../hooks/useCRUD";

const Dashboard = ({ groupId }) => {
  const [pickupData, setPickupData] = useState(null);

  const [flightGroups, setFlightGroups] = useState({});

  const { updateItem: updateStudentStatus } = useCRUD("/api/students");

  // Fetch data function
  const fetchData = async () => {
    try {
      const data = await getPickupData(groupId);
      if (data) {
        setPickupData(data);
      } else {
        setPickupData(null);
      }
    } catch (error) {
      console.error("Error fetching pickup data:", error);
      setPickupData(null);
    }
  };

  useEffect(() => {
    fetchData();
  }, [groupId]);

  useEffect(() => {
    if (pickupData?.students) {
      const groupedByFlight = pickupData.students.reduce((acc, student) => {
        acc[student.flightNumber] = [
          ...(acc[student.flightNumber] || []),
          student,
        ];
        return acc;
      }, {});
      setFlightGroups(groupedByFlight);
    }
  }, [pickupData]);

  const toggleCheckInStatus = async (student) => {
    try {
      console.log(student);
      await updateStudentStatus(student._id, {
        isCheckedIn: !student.isCheckedIn,
      });
      fetchData(); // Refresh data after successful update
    } catch (error) {
      console.error("Error toggling check-in status:", error);
    }
  };

  const toggleCancelStatus = async (student) => {
    try {
      await updateStudentStatus(student._id, {
        isCanceled: !student.isCanceled,
      });
      fetchData(); // Refresh data after successful update
    } catch (error) {
      console.error("Error toggling cancel status:", error);
    }
  };

  if (!pickupData) {
    return <div>Loading...</div>; // or any other loading/error state representation
  }

  return (
    <div>
      <Banner pickupData={pickupData} />

      {Object.keys(flightGroups).map((flightNumber) => (
        <FlightCard
          key={flightNumber}
          flightNumber={flightNumber}
          students={flightGroups[flightNumber]}
          toggleCancelStatus={toggleCancelStatus}
          toggleCheckInStatus={toggleCheckInStatus}
          pickupData={pickupData}
        />
      ))}
    </div>
  );
};

export default Dashboard;
