import { Box } from "@chakra-ui/react";

import { useParams } from "react-router-dom";

import Dashboard from "../components/PickupDashboard/Dashboard";

const PickupDashboard = () => {
  const { id } = useParams(); // Extracting the id parameter from the URL

  return (
    <Box p={0}>
      <Dashboard groupId={id} />
    </Box>
  );
};

export default PickupDashboard;
