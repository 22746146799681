import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  ExternalLinkIcon,
  CloseIcon,
  EditIcon,
  DeleteIcon,
} from "@chakra-ui/icons";
import {
  Button,
  Box,
  Heading,
  Text,
  useToast,
  SimpleGrid,
  VStack,
  Badge,
  IconButton,
  Flex,
  Avatar,
  Tooltip,
} from "@chakra-ui/react";

import { formatDate } from "../../utils/dateUtils";
import { deleteVolunteerFromSlot } from "../../services/ApiService";
import UploadProfilePicModal from "./UploadProfilePicModal";

const UserContent = ({ user }) => {
  const [slots, setSlots] = useState([]);
  const [profilePicUrl, setProfilePicUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const fetchProfilePicUrl = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/users/profile-pic`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ mobilePhone: user.mobilePhone }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch profile picture URL.");
        }

        const data = await response.json();
        setProfilePicUrl(data.profilePicUrl);
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    const fetchSlots = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/users/my-slots`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ mobilePhone: user.mobilePhone }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch slots.");
        }

        const data = await response.json();
        setSlots(data);
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchProfilePicUrl();
    fetchSlots();
  }, [user.mobilePhone, toast]);

  const cancelVolunteerSlot = async (slotId, volId) => {
    if (
      !window.confirm("Are you sure you want to cancel this volunteer slot?")
    ) {
      return;
    }
    try {
      const response = await deleteVolunteerFromSlot(slotId, volId);

      if (response.success) {
        setSlots(slots.filter((slot) => slot._id !== slotId));
        toast({
          title: "Slot Cancelled",
          description: "You have successfully cancelled the volunteer slot.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to cancel slot.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteProfilePic = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/update-profile-pic-url`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: user._id, profileUrl: "" }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to delete profile picture.");
      }

      setProfilePicUrl("");
      toast({
        title: "Profile Picture Deleted",
        description: "Your profile picture has been deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to delete profile picture.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box w="100%" p={5}>
      <Flex align="center" mb={4}>
        <Box position="relative" mr={4}>
          <Avatar size="xl" src={profilePicUrl} />
          <Flex position="absolute" bottom="0" right="0">
            <Tooltip label="Edit Profile Picture">
              <IconButton
                icon={<EditIcon />}
                size="sm"
                colorScheme="blue"
                aria-label="Edit Profile Picture"
                onClick={() => setIsModalOpen(true)}
                mr={1}
              />
            </Tooltip>
            {profilePicUrl !== "" && (
              <Tooltip label="Delete Profile Picture">
                <IconButton
                  icon={<DeleteIcon />}
                  size="sm"
                  colorScheme="red"
                  aria-label="Delete Profile Picture"
                  onClick={handleDeleteProfilePic}
                />
              </Tooltip>
            )}
          </Flex>
        </Box>
        <Heading as="h1" size="xl">
          Welcome, {user.firstName}
        </Heading>
      </Flex>

      {profilePicUrl === "" && (
        <Box mb={4} p={4} borderWidth="1px" borderRadius="lg">
          <Text fontSize="lg" color="gray.600">
            Please upload a profile picture to complete your profile. Uploading
            a pic will let the student know who you are.
          </Text>
          <Button
            colorScheme="blue"
            mt={2}
            onClick={() => setIsModalOpen(true)}
          >
            Upload Profile Picture
          </Button>
        </Box>
      )}

      <Text fontSize="lg" color="gray.600" mb={4}>
        You have {slots.length} upcoming volunteer slots.
      </Text>

      <SimpleGrid columns={[1, 2, 3]} spacing="20px">
        {slots.map((slot) => (
          <Box
            key={slot._id}
            p={5}
            shadow="md"
            borderWidth="1px"
            borderRadius="lg"
            position="relative"
          >
            <IconButton
              icon={<CloseIcon />}
              size="xs"
              colorScheme="red"
              aria-label="Cancel Slot"
              position="absolute"
              top={-3}
              right={-2}
              onClick={() => cancelVolunteerSlot(slot._id, slot.vol_id)}
            />
            <VStack align="stretch" spacing={3}>
              <Badge colorScheme="yellow" variant="solid">
                <Text fontWeight="bold" textAlign={"center"} fontSize="md">
                  {formatDate(new Date(slot.pickupDateTime))}
                </Text>
              </Badge>
              {slot.isDriver && (
                <Text>Can Accommodate: {slot.canAccomodate}</Text>
              )}
              <Text>
                Volunteer Type:{" "}
                {slot.isDriver ? "Driver" : slot.isGreeter ? "Greeter" : "N/A"}
                {slot.canDriveChurchVan && " (Church Van)"}
              </Text>
              {slot.personInCharge && (
                <Badge
                  colorScheme="purple"
                  variant="solid"
                  alignSelf="flex-start"
                >
                  Coordinator
                </Badge>
              )}

              <Button
                as={Link}
                to={`/dashboard/${slot.groupId}`}
                rightIcon={<ExternalLinkIcon />}
                colorScheme="teal"
                variant="outline"
                mt={2}
              >
                Dashboard
              </Button>
            </VStack>
          </Box>
        ))}
      </SimpleGrid>

      <UploadProfilePicModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        userId={user._id}
        onUpload={() => setProfilePicUrl("uploaded")}
      />
    </Box>
  );
};

export default UserContent;
