import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Button,
  Grid,
  Select,
  Textarea,
  Tooltip,
  Box,
  Heading,
  // ... other necessary components
} from "@chakra-ui/react";

const CreateStudentModal = ({ isOpen, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    // Initialize with empty or default values for each field
    firstName: "",
    lastName: "",
    email: "",
    mobilePhone: "",
    whatsappNumber: "",
    gender: "",
    maritalStatus: "",
    countryOfOrigin: "",

    requestedPickupDateTime: "",
    airlineName: "",
    flightNumber: "",
    arrivalDateTime: "",

    familyMembersArrivingWithYou: "",
    numberOfPeopleComing: "",
    numberOfCheckedInLuggage: "",

    dropOffDestination: "",
    destinationAddress: "",

    comments: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    setFormData({}); // Reset form after submission
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Student</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="createStudentForm" onSubmit={handleSubmit}>
            {/* Student Details */}
            <Box mb={6}>
              <Heading size="md" mb={4}>
                Student Details
              </Heading>
              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                {/* First Name */}
                <FormControl mb={4}>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    name="firstName"
                    value={formData?.firstName || ""}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* Last Name */}
                <FormControl mb={4}>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    name="lastName"
                    value={formData?.lastName || ""}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* Email */}
                <FormControl mb={4}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    name="email"
                    value={formData?.email || ""}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* Mobile Phone */}
                <FormControl mb={4}>
                  <FormLabel>Mobile Phone</FormLabel>
                  <Input
                    name="mobilePhone"
                    value={formData?.mobilePhone || ""}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* WhatsApp Number */}
                <FormControl mb={4}>
                  <FormLabel>WhatsApp #</FormLabel>
                  <Input
                    name="whatsappNumber"
                    value={formData?.whatsappNumber || ""}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* Gender */}
                <FormControl mb={4}>
                  <Tooltip label="If more than one, please separate with commas so that it tallies in the count.">
                    <FormLabel>Gender</FormLabel>
                  </Tooltip>

                  <Input
                    name="gender"
                    value={formData?.gender || ""}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* Country of Origin */}
                <FormControl mb={4}>
                  <FormLabel>Country of Origin</FormLabel>
                  <Input
                    name="countryOfOrigin"
                    value={formData?.countryOfOrigin || ""}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* Marital Status */}
                <FormControl mb={4}>
                  <FormLabel>Marital Status</FormLabel>
                  <Select
                    name="maritalStatus"
                    value={formData?.maritalStatus || ""}
                    onChange={handleChange}
                  >
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                  </Select>
                </FormControl>
              </Grid>
            </Box>

            {/* Flight Details */}
            <Box mb={6}>
              <Heading size="md" mb={4}>
                Flight Details
              </Heading>
              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                {/* Flight Number */}
                <FormControl mb={4}>
                  <FormLabel>Flight Number</FormLabel>
                  <Input
                    name="flightNumber"
                    value={formData?.flightNumber || ""}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* Airline Name */}
                <FormControl mb={4}>
                  <FormLabel>Airline Name</FormLabel>
                  <Input
                    name="airlineName"
                    value={formData?.airlineName || ""}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* Arrival Date/Time */}
                <FormControl mb={4}>
                  <FormLabel>Arrival Date/Time</FormLabel>
                  <Input
                    type="text"
                    name="arrivalDateTime"
                    value={formData?.arrivalDateTime || ""}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* Requested Pickup Date/Time */}
                <FormControl mb={4}>
                  <FormLabel> Requested Pickup Date/Time</FormLabel>
                  <Input
                    type="text"
                    name="requestedPickupDateTime"
                    value={formData?.requestedPickupDateTime || ""}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* Number of People Coming */}
                <FormControl mb={4}>
                  <FormLabel>Number of People Coming</FormLabel>
                  <Input
                    type="number"
                    name="numberOfPeopleComing"
                    value={formData?.numberOfPeopleComing || ""}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* Family Members Coming*/}
                <FormControl mb={4}>
                  <FormLabel>Family Members Coming</FormLabel>
                  <Input
                    type="text"
                    name="familyMembersArrivingWithYou"
                    value={formData?.familyMembersArrivingWithYou || ""}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* Number of Checked-In Luggage */}
                <FormControl mb={4}>
                  <FormLabel>Number of Checked-In Luggage</FormLabel>
                  <Input
                    type="number"
                    name="numberOfCheckedInLuggage"
                    value={formData?.numberOfCheckedInLuggage || ""}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Box>

            {/* Drop-off Details */}
            <Box mb={6}>
              <Heading size="md" mb={4}>
                Drop-off Details
              </Heading>
              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                {/* Drop-off Destination */}
                <FormControl mb={4}>
                  <FormLabel>Drop-off Destination</FormLabel>
                  <Input
                    name="dropOffDestination"
                    value={formData?.dropOffDestination || ""}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* Destination Address */}
                <FormControl mb={4}>
                  <FormLabel>Destination Address</FormLabel>
                  <Input
                    name="destinationAddress"
                    value={formData?.destinationAddress || ""}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* Comments */}
                <FormControl mb={4}>
                  <FormLabel>Comments</FormLabel>
                  <Textarea
                    name="comments"
                    value={formData?.comments || ""}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* Additional Prior Connecting Flight Numbers */}
                <FormControl mb={4}>
                  <FormLabel>
                    Additional Prior Connecting Flight Numbers
                  </FormLabel>
                  <Input
                    name="additionalPriorConnectingFlightNumbers"
                    value={
                      formData?.additionalPriorConnectingFlightNumbers || ""
                    }
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Box>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button colorScheme="green" form="createStudentForm" type="submit">
            Create Student
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateStudentModal;
